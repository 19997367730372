/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  GameBannerCard,
  GameBannerCard2,
  GameBannerCasino,
  GameBannerCasino2,
  GameBannerInstantGame,
  GameBannerInstantGame2,
  GameBannerLive,
  GameBannerLive2,
  GameBannerSlots,
  GameBannerSlots2,
  GameBannerTable,
  GameBannerTable2,
  GameBannerLiveMobile,
  GameBannerLiveMobile2,
  GameBannerSlotsMobile,
  GameBannerSlotsMobile2,
  GameBannerCasinoMobile,
  GameBannerCasinoMobile2,
  GameBannerInstantGameMobile,
  GameBannerInstantGameMobile2,
  GameBannerTableGameMobile,
  GameBannerTableGameMobile2,
  GameBannerCardGameMobile,
  GameBannerCardGameMobile2,
  GameBannerSlots3,
  GameBannerSlots4,
  GameBannerSlotsMobile3,
  GameBannerSlotsMobile4,
} from '../../images';
import Carousel from '../dashboard/Carousel';

function Banner({ type }) {
  const router = useRouter();

  function _renderText() {
    if (type === 8 || type === '8') {
      return (
        <>
          <div className='hidden md:block'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/live-casino'); }}
                  >
                    <Image src={GameBannerLive} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/live-casino'); }}
                  >
                    <Image src={GameBannerLive2} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                </>
              )}
            />
          </div>

          <div className='md:hidden'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/live-casino'); }}
                  >
                    <Image src={GameBannerLiveMobile} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/live-casino'); }}
                  >
                    <Image src={GameBannerLiveMobile2} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                </>
              )}
            />
          </div>
        </>

      );
    } if (type === 12 || type === '12') {
      return (
        <>
          <div className='hidden md:block'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/slots'); }}
                  >
                    <Image src={GameBannerSlots} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  {/* <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/slots/netent'); }}
                  >
                    <Image src={GameBannerSlots3} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/slots/nolimitcity'); }}
                  >
                    <Image src={GameBannerSlots4} alt='Top Indian gambling' placeholder='empty' />
                  </div> */}
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/slots'); }}
                  >
                    <Image src={GameBannerSlots2} alt='Top Indian gambling' placeholder='empty' />
                  </div>

                </>
              )}
            />
          </div>

          <div className='md:hidden'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/slots'); }}
                  >
                    <Image src={GameBannerSlotsMobile} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  {/* <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/slots/netent'); }}
                  >
                    <Image src={GameBannerSlotsMobile3} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/slots/nolimitcity'); }}
                  >
                    <Image src={GameBannerSlotsMobile4} alt='Top Indian gambling' placeholder='empty' />
                  </div> */}
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/slots'); }}
                  >
                    <Image src={GameBannerSlotsMobile2} alt='Top Indian gambling' placeholder='empty' />
                  </div>

                </>
              )}
            />
          </div>
        </>
      );
    } if (type === 5 || type === '5') {
      return (
        <>
          <div className='hidden md:block'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/live-casino'); }}
                  >
                    <Image src={GameBannerCasino} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/live-casino'); }}
                  >
                    <Image src={GameBannerCasino2} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                </>
              )}
            />
          </div>

          <div className='md:hidden'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/live-casino'); }}
                  >
                    <Image src={GameBannerCasinoMobile} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/live-casino'); }}
                  >
                    <Image src={GameBannerCasinoMobile2} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                </>
              )}
            />
          </div>
        </>
      );
    } if (type === 1 || type === '1') {
      return (
        <>
          <div className='hidden md:block'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/instant-game'); }}
                  >
                    <Image src={GameBannerInstantGame} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/instant-game'); }}
                  >
                    <Image src={GameBannerInstantGame2} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                </>
              )}
            />
          </div>

          <div className='md:hidden'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/instant-game'); }}
                  >
                    <Image src={GameBannerInstantGameMobile} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/instant-game'); }}
                  >
                    <Image src={GameBannerInstantGameMobile2} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                </>
              )}
            />
          </div>
        </>
      );
    } if (type === 3 || type === '3') {
      return (
        <>
          <div className='hidden md:block'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/table-game'); }}
                  >
                    <Image src={GameBannerTable} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/table-game'); }}
                  >
                    <Image src={GameBannerTable2} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                </>
              )}
            />
          </div>

          <div className='md:hidden'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/table-game'); }}
                  >
                    <Image src={GameBannerTableGameMobile} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/table-game'); }}
                  >
                    <Image src={GameBannerTableGameMobile2} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                </>
              )}
            />
          </div>
        </>
      );
    } if (type === 4 || type === '4') {
      return (
        <>
          <div className='hidden md:block'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/card-game'); }}
                  >
                    <Image src={GameBannerCard} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/card-game'); }}
                  >
                    <Image src={GameBannerCard2} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                </>
              )}
            />
          </div>

          <div className='md:hidden'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/card-game'); }}
                  >
                    <Image src={GameBannerCardGameMobile} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/card-game'); }}
                  >
                    <Image src={GameBannerCardGameMobile2} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                </>
              )}
            />
          </div>
        </>
      );
    }
    // if (type === 13) {
    //   return (
    //     <div>
    //       <Image   src={GameBanner4d} alt="Top Indian gambling" />
    //     </div>
    //   );
    // }
    if (type === 0 || type === '0') {
      return (
        <>
          <div className='hidden md:block'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/slots'); }}
                  >
                    <Image src={GameBannerSlots} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  {/* <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/slots/netent'); }}
                  >
                    <Image src={GameBannerSlots3} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/slots/nolimitcity'); }}
                  >
                    <Image src={GameBannerSlots4} alt='Top Indian gambling' placeholder='empty' />
                  </div> */}
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/slots'); }}
                  >
                    <Image src={GameBannerSlots2} alt='Top Indian gambling' placeholder='empty' />
                  </div>

                </>
              )}
            />
          </div>

          <div className='md:hidden'>
            <Carousel
              id={`game-banner-${type}`}
              className=''
              items={(
                <>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/slots/nolimitcity'); }}
                  >
                    <Image src={GameBannerSlotsMobile} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  {/* <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/slots/netent'); }}
                  >
                    <Image src={GameBannerSlotsMobile3} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/slots/nolimitcity'); }}
                  >
                    <Image src={GameBannerSlotsMobile4} alt='Top Indian gambling' placeholder='empty' />
                  </div> */}
                  <div
                    className='flex-item-game-banner cursor-pointer rounded-lg overflow-hidden'
                    onClick={() => { router.push('/promotions/slots'); }}
                  >
                    <Image src={GameBannerSlotsMobile2} alt='Top Indian gambling' placeholder='empty' />
                  </div>
                </>
              )}
            />
          </div>
        </>
      );
    }
  }

  return (
    <div className='text-white'>
      {_renderText()}
    </div>
  );
}

export default Banner;
